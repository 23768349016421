<template>
  <el-button-group class='year-select-container' size='mini'>
    <el-button type='primary' icon='el-icon-arrow-left' @click='prev'>上一年</el-button>
    <el-button type='primary' class='date-out'>
      <el-date-picker :key='year' v-model='year'
                      value-format='yyyy'
                      format='yyyy年'
                      type='year'
                      placeholder='选择年份'
                      :clearable='false' size='mini' class='date-picker' />
      <i class='el-icon-arrow-down' />
    </el-button>
    <el-button type='primary' @click='next'>
      下一年
      <i class='el-icon-arrow-right el-icon--right' />
    </el-button>
  </el-button-group>
</template>

<script>
import moment from 'moment'

export default {
  name: 'YearSelectGroup',
  props: {
    value: {
      type: [String, Number]
    }
  },
  data() {
    return {
      year: null
    }
  },
  watch: {
    year(_) {
      this.$emit('update:value', _)
    },
    value(_) {
      if (_ !== this.year) {
        this.year = _
      }
    }
  },
  created() {
    this.year = this.value ? this.value : moment().format('YYYY')
  },
  methods: {
    prev() {
      this.year = (parseInt(this.year) - 1) + ''
    },
    next() {
      this.year = (parseInt(this.year) + 1) + ''
    }
  }
}
</script>

<style lang='scss'>
.year-select-container {
  width: 100%;

  .el-button {
    width: calc(100% / 3);
  }

  .date-out {
    .date-picker {
      width: 40px;

      .el-input__inner {
        background: transparent;
        color: #FFFFFF;
        border: unset;
        height: 10px !important;
        line-height: 12px !important;
        padding-left: 0;
        padding-right: 0;
        cursor: pointer;
        caret-color: transparent;
      }

      .el-input__prefix {
        display: none;
      }
    }
  }
}
</style>
