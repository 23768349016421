<template>
  <div class='ml-upload-images'>
    <ml-upload-image v-for='image in images' :key='image.id' class='form-item-image' :id.sync='image.id'
                     :url="image.src||''" @delete='eventDelete'>
    </ml-upload-image>
    <ml-upload-image v-if='images.length<5' :key='key' @success='eventSuccess' custom>
      <slot />
    </ml-upload-image>
  </div>
</template>
<script>
import MlUploadImage from './single.vue'

const getKey = function() {
  return '' + (new Date().getTime())
}
export default {
  name: 'MlUploadImages',
  components: { MlUploadImage },
  props: {
    imagesParam: [Array, Boolean]
  },
  data() {
    return {
      key: getKey(),
      images: []
    }
  },
  created() {
    if (Array.isArray(this.imagesParam)) {
      this.images = [...[], ...this.imagesParam]
    }
  },
  methods: {
    /**
     * 单个图片上传成功
     * @param _
     */
    eventSuccess: function(_) {
      this.images = this.uniqueImages(_)
      this.key = getKey()
    },
    /**
     * 单个图片删除
     * @param id
     */
    eventDelete: function(id) {
      const index = this.images.findIndex(_ => {
        return _.id === id
      })
      if (index > -1) {
        this.images.splice(index, 1)
      }
    },
    /**
     * 图片去重
     * @param _
     * @returns {*[]}
     */
    uniqueImages: function(_) {
      const data = [...[], ...this.images]
      data.push(_)
      const length_n = data.length
      let ids = [], images = []
      data.map(datum => {
        if (!ids.includes(datum.id)) {
          ids.push(datum.id)
          images.push(datum)
        }
      })
      if (length_n > images.length) {
        this.$message.warning('图片重复了！')
      }
      return images
    }
  }
}
</script>
<style scoped lang='scss'>
.ml-upload-images {
  display: flex;
  align-items: center;

  .ml-upload-image + .ml-upload-image {
    margin-left: 12px;
  }
}
</style>
