<template>
  <div class='upload-popular-container' style='display: inline'>
    <el-upload
      ref='upload'
      :show-file-list='false'
      :disabled='upload.loading'
      :headers='upload.header'
      :before-upload='importBefore'
      :on-change='importChange'
      :on-success='importSuccess'
      accept='.xlsx'
      action='/admin_api/popular_case/import'
      style='display: inline;margin-left: 10px;'
    >
      <el-button icon='el-icon-upload'>导入</el-button>
    </el-upload>

    <el-drawer custom-class='dialog-preview' :title='`导入预览【${upload.file_name}】`'
               :visible.sync='dialogUploadList' :close-on-click-modal='false' :close-on-press-escape='false'
               direction='btt'
               :show-close='importObj.status!==MapStatus.DOING' center width='90%'>
      <div class='main'>
        <div class='left'>
          <div class='line' />
          <el-table :key='tableKey' :data='table.list' border size='mini' height='calc(100% - 10px)'
                    :row-style='styleTableRow'>
            <el-table-column label='序号' width='50'>
              <template slot-scope='{$index}'>{{ $index + 1 }}</template>
            </el-table-column>
            <el-table-column v-for='col in table.cols' :key='col.field' :prop='col.field' :label='col.title'
                             :width="col['el-width']" show-overflow-tooltip>
              <template v-if='col.col_type==="img"' v-slot='{row}'>
                <el-image
                  :src='row[col.field]' :alt='row[col.field]' style='width: 100%;height: auto'
                  width='40px' fit='scale-down'
                  :preview-src-list='[row[col.field]]' />
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class='right'>
          <div class='line' />
          <div class='content'>
            <div style='text-align: center;font-weight: bold;margin-bottom: 20px;'>
              {{ Array.isArray(table.errs) && table.errs.length ? 'Excel数据不完善' : 'Excel数据' }}
            </div>
            <template v-if='importObj.status===MapStatus.WAIT'>
              <!--       等待上传       -->
              <div v-if='Array.isArray(table.errs) && table.errs.length' class='error'>
                <!--       Excel数据异常       -->
                <div v-for='(err,err_i) in table.errs' :key='err_i' style='margin-bottom: 6px;font-size: 12px;'>
                  <span class='title'>{{ err_i + 1 }}.</span>
                  <span class='desc'>{{ err }}</span>
                </div>
              </div>
              <div v-else style='display: flex;align-items: center;justify-content: center;'>
                <!--       Excel数据准备导入       -->
                <div class='statistic'>
                  <div>总计 <span>{{ table.list.length }}</span> 条</div>
                  <div>未开始导入<br />当前进度0%</div>
                  <el-progress type='circle' :percentage='importObj.progress' style='margin-top: 30px;'
                               color='#FF3176' />
                </div>
              </div>
            </template>
            <div v-else style='display: flex;align-items: center;justify-content: center;'>
              <!--       Excel导入       -->
              <div class='statistic'>
                <div>总计 <span>{{ table.list.length }}</span> 条</div>
                <div>未导入 <span>{{ table.list.length - importObj.num_success }}</span> 条</div>
                <div>成功导入 <span class='success'>{{ importObj.num_success }}</span> 条</div>
                <div>导入失败 <span class='fail'>{{ importObj.num_fail }}</span> 条</div>
                <el-progress type='circle' :percentage='importObj.progress' style='margin-top: 30px;' color='#FF3176' />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class='drawer-footer'>
        <template v-if='importObj.status!==MapStatus.DONE'>
          <template v-if='Array.isArray(table.errs) && table.errs.length'>
            <el-upload
              :show-file-list='false'
              :disabled='upload.loading'
              :headers='upload.header'
              :before-upload='importBefore'
              :on-change='importChange'
              :on-success='importSuccess'
              accept='.xlsx'
              action='/admin_api/popular_case/import'
              style='display: inline;margin-left: 10px;'
            >
              <el-button type='primary'>重新上传</el-button>
            </el-upload>
          </template>
          <template v-else>
            <el-button v-if='importObj.status===MapStatus.WAIT' @click='dialogUploadList = false'>取消导入</el-button>
            <el-button :loading='loadingSure' type='primary' @click='handelSure'>确定导入</el-button>
          </template>
        </template>
        <el-button v-else type='primary' @click='dialogUploadList = false'>导入结束</el-button>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { getToken } from '@/utils/auth'
import { savePopularCase } from '../../../api/popular_case'

const ModelUpload = {
  header: { 'HYPERF-SESSION-ID': getToken() },
  loading: false,
  file: [],
  file_name: ''
}
const MapStatus = {
  WAIT: 'WAIT',
  DOING: 'DOING',
  DONE: 'DONE'
}
const ModelImport = {
  status: MapStatus.WAIT,
  num_success: 0,
  num_fail: 0,
  progress: 0
}

export default {
  name: 'PopularCaseImport',
  data() {
    return {
      MapStatus,
      loadingFullscreen: false,
      loadingSure: false,
      importObj: { ...{}, ...ModelImport },
      tableKey: new Date().getTime(),
      table: {
        errs: [],
        list: [],
        cols: []
      },
      dialogUploadList: false,
      upload: { ...{}, ...ModelUpload }
    }
  },
  watch: {
    'importObj.num_success'(_) {
      const total = this.table.list.length || 0
      this.importObj.progress = total ? parseInt((_ * 100 / total) + '') : 0
    },
    dialogUploadList(_) {
      if (!_) {
        this.$emit('success')
      }
    }
  },
  created() {
  },
  methods: {
    async handelSure() {
      if (Array.isArray(this.table.errs) && this.table.errs.length) {
        this.$message.warning('Excel数据不完善，请完善后导入！')
        return
      }
      const _this = this
      _this.loadingSure = true
      _this.importObj.status = MapStatus.DOING
      for (let datumIndex = 0; datumIndex < _this.table.list.length; datumIndex++) {
        const datum = _this.table.list[datumIndex]
        datum.is_import = true
        if (datum.pic_poster_id) {
          datum.images = [{ id: datum.pic_poster_id }]
        }
        const response = await savePopularCase(datum)
        if (response) {
          _this.importObj.num_success++
          datum.import = 'success'
        } else {
          datum.import = 'fail'
        }
      }
      _this.importObj.status = MapStatus.DONE
      _this.importObj.num_fail = _this.table.list.length - _this.importObj.num_success
      _this.$message.info('导入结束！')
      _this.tableKey = new Date().getTime()
      _this.loadingSure = false
    },
    styleTableRow({ row }) {
      if (row.import === 'fail') {
        return {
          backgroundColor: '#FFF',
          color: '#fe2d54'
        }
      }
    },
    importBefore() {
      // console.log('importBefore')
    },
    importChange(file) {
      if (file.status !== 'ready') {
        return
      }
      this.loadingFullscreen = this.$loading({
        lock: true,
        text: '解析文件中...请稍后',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.7)'
      })
      this.table.list = []
      this.importObj = { ...{}, ...ModelImport }
      this.upload = { ...{}, ...ModelUpload }
      this.upload.file = [file]
      this.upload.file_name = file.name
      setTimeout(() => {
        if (this.loadingFullscreen) {
          // console.log('超时')
          this.closeLoading()
          this.$confirm('导入超时，请联系管理员。', '导入失败', {
            confirmButtonText: '确定',
            showCancelButton: false,
            showClose: false,
            closeOnClickModal: false,
            closeOnPressEscape: false,
            type: 'warning'
          })
        }
        // else {
        // console.log('正常')
        // }
      }, 1000 * 60 * 10)
    },
    importSuccess(response) {
      if (!this.loadingFullscreen) {
        // 超时后 loadingFullscreen 关闭，放弃弹窗行为
        return
      }
      if (response.code !== 200) {
        this.$confirm(response.msg, '导入失败', {
          confirmButtonText: '确定',
          showCancelButton: false,
          showClose: false,
          closeOnClickModal: false,
          closeOnPressEscape: false,
          type: 'warning'
        }).then(() => {
          this.closeLoading()
        }).catch(() => {
        })
        return
      }
      this.table = response.data || []
      this.dialogUploadList = true
      this.closeLoading()
    },
    closeLoading: function() {
      this.loadingFullscreen && this.loadingFullscreen.close()
      this.loadingFullscreen = false
    }
  }
}
</script>

<style lang='scss'>
.upload-popular-container {
  @font-face {
    font-family: 'FjallaOne-Regular';
    src: url("~@/assets/font/FjallaOne-Regular.ttf");
  }
  font-family: FjallaOne-Regular, DINPro Medium, sans-serif !important;

  .dialog-preview {
    .el-drawer__body {
      padding: 10px 20px 20px 20px;
      overflow-y: hidden;
    }

    .el-drawer__header {
      margin-bottom: 18px !important;
    }

    .el-drawer__header > :first-child {
      text-align: center !important;
      font-weight: 700;
      font-size: 14px;
    }

    .drawer-footer {
      text-align: center;
      margin-top: 18px;
    }

    .main {
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: calc(100% - 38px);

      .left {
        width: calc(100% - 270px);
        height: 100%;
      }

      .statistic {
        text-align: center;
        line-height: 1.5;

        span {
          font-size: 18px;
          font-weight: bold;
        }

        .success {
          color: darkgreen;
        }

        .fail {
          color: #fe2d54;
        }
      }


      .line {
        height: 10px;
        border-radius: 10px 10px 0 0;
        background: #FF3176;
      }

      .right {
        width: 250px;
        margin-left: 20px;
        height: 100%;
        overflow-y: hidden;

        .content {
          border: 1px solid #EBEEF5;
          padding: 8px;
          text-align: left;
          height: calc(100% - 27px);

          .error {
            height: calc(100% - 42px);
            overflow-y: auto;
          }

          .title {
            font-size: 12px;
          }

          .desc {
            color: #909399;
            margin-top: 2px;
          }
        }
      }
    }
  }

  .el-drawer {
    height: 80% !important;
  }
}
</style>
