<template>
  <el-input
    ref='input'
    :value='data'
    :placeholder='placeholder'
    clearable
    :disabled='disabled'
    @input='handelChangeValue($event)'
  >
    <i v-if='prefixIcon' slot='prefix' :class='`el-input__icon iconfont ${prefixIcon}`' />
    <i v-if='suffixIcon' slot='suffix' :class='`el-input__icon iconfont ${suffixIcon}`' />
  </el-input>
</template>
<script>

export default {
  name: 'MlInput',
  props: {
    value: [String, Boolean, Number, Object],
    placeholder: String,
    prefixIcon: String,
    suffixIcon: String,
    num: Boolean,
    int: Boolean,
    disabled: Boolean
  },
  data() {
    return {
      data: null
    }
  },
  watch: {
    value: function(_) {
      this.data = _
    },
    data: function(_) {
      this.$emit('update:value', _)
    }
  },
  created() {
    this.data = this.value
  },
  methods: {
    /**
     * 输入框 input 事件
     * 限制小数或整数
     * @param value
     */
    handelChangeValue: function(value) {
      if (this.int) {
        this.handelChangeValueInt(value)
        return
      }
      if (!this.num) {
        this.data = value
        return
      }
      const _val = value.split(',').join('')
      // eslint-disable-next-line no-empty
      if (isNaN(Number(_val))) {

      } else {
        const _indexOf = _val.indexOf('.')
        if (_indexOf >= 0) {
          if (_val.length - 1 - _indexOf <= 2) {
            this.data = _val
          }
        } else {
          this.data = _val
        }
      }
    },
    /**
     * 整数限制
     * @param value
     */
    handelChangeValueInt: function(value) {
      const _val = value.split(',').join('')
      // eslint-disable-next-line no-empty
      if (isNaN(Number(_val))) {

      } else {
        const _indexOf = _val.indexOf('.')
        if (_indexOf === -1) {
          this.data = _val
        }
      }
    }
  }
}
</script>
<style lang='scss'>
</style>
